/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
type DataLayerType = {
  event: string;
  err_message?: string;
  user_id?: string;
};

const WEBHOOK_URL =
  'https://hooks.slack.com/services/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';

const SWAN_URL = 'https://analytics.sirclo.com';

const dashboadCollections = [
  {
    id: 'bfb415cd-74ef-443b-9fe9-76c0ecd551c5',
    title: 'Swiftanalytics: Home',
    url: '/',
  },
  {
    id: '3af1364c-269c-4609-bbc9-04d9f85de082',
    title: 'Swiftanalytics: Sales - Daily & Run Rate',
    url: '/sales/daily-and-run-rate',
  },
  {
    id: 'ec1bd9bc-1e98-428a-93e0-790c20000439',
    title: 'Swiftanalytics: Sales - SKU Contribution',
    url: '/sales/sku-contribution',
  },
  {
    id: '6d2897ed-a5bd-4cc7-b4b4-55ea51a1cf7c',
    title: 'Swiftanalytics: Sales - Heatmap Calendar',
    url: '/sales/heatmap-calendar',
  },
  {
    id: '5cab864e-1992-4f14-94ca-175b4c52dc83',
    title: 'Swiftanalytics: Sales - By Area',
    url: '/sales/by-area',
  },
  {
    id: 'b98d31ce-7230-4915-94db-88a5f149cc19',
    title: 'Swiftanalytics: Sales - By Shipping',
    url: '/sales/by-shipping',
  },
  {
    id: 'b88508e0-d0ce-4922-944f-340d61256efe',
    title: 'Swiftanalytics: Sales - Raw Data',
    url: '/sales/raw-data',
  },
  {
    id: '70de4797-5c0b-4285-bb3e-456128663e41',
    title: 'Swiftanalytics: Customer Behaviour - Acquisition',
    url: '/cb/acquisition',
  },
  {
    id: '54d6e95a-f7b7-4b2c-b2c8-0c086c044b89',
    title: 'Swiftanalytics: Customer Behaviour - Shopping Behavior',
    url: '/cb/shopping-behavior',
  },
  {
    id: '9e5a1b88-aee6-4d61-96d6-945411aa6bab',
    title: 'Swiftanalytics: Customer Behaviour - Retention',
    url: '/cb/retention',
  },
  {
    id: '908eb71d-6f4b-41c6-ad6f-ad814b086f06',
    title: 'Swiftanalytics: Customer Behaviour - Cohort Analysis',
    url: '/cb/cohort-analysis',
  },
  {
    id: '62484d9c-2104-4015-ac6c-56cc6e6cad5f',
    title: 'Swiftanalytics: Customer Behaviour - Gender Analysis',
    url: '/cb/gender-analysis',
  },
  {
    id: 'f3542e55-867b-42fb-aa1f-da51d75fd40d',
    title: 'Swiftanalytics: Customer Behaviour - Basket Size & Assortment',
    url: '/cb/basket-size-assortment',
  },
  {
    id: 'bd800f8c-71e4-4181-aeb5-598991aef12c',
    title: 'Swiftanalytics: Market Intelligence - Category Trend (β)',
    url: '/market-intelligence/category-trend',
  },
  {
    id: '3a3b8a3e-9e04-466c-b0e7-8100af085729',
    title: 'Swiftanalytics: Market Intelligence - Product (β)',
    url: '/market-intelligence/product',
  },
  {
    id: '9f4dfc40-1d39-4d03-963f-717f29020a33',
    title: 'Swiftanalytics: Market Intelligence - Brand(β)',
    url: '/market-intelligence/brand',
  },
  {
    id: '07802a1f-b403-40fb-901c-30d61489b574',
    title: 'Swiftanalytics: Market Intelligence - Store (β)',
    url: '/market-intelligence/store',
  },
  {
    id: '24a78185-7671-4e78-902b-e718007db078',
    title: 'Swiftanalytics: Market Intelligence - Rating (β)',
    url: '/market-intelligence/rating',
  },
  {
    id: '2338fe46-5d0e-48b2-a1c6-8fb2c36909fd',
    title: 'Swiftanalytics: Market Intelligence - Competitor Monitoring (β)',
    url: '/market-intelligence/competitor-monitoring',
  },
  {
    id: 'a7dd4e7a-1bd8-43d4-9ac5-06fed0dc68ae',
    title: 'Swiftanalytics: Operations Data - Order Movement (β)',
    url: '/ops-data/order-movement',
  },
  {
    id: '4fcb376c-2411-425f-b3b9-3d0d22300edf',
    title: 'Swiftanalytics: Operations Data - Time To Serve (β)',
    url: '/ops-data/time-to-serve',
  },
  {
    id: 'bc311545-e860-44ef-b20d-7894036595bc',
    title: 'Swiftanalytics: Operations Data - Purchase Order (β)',
    url: '/ops-data/puchase-order',
  },
  {
    id: '6b527e1e-0f45-406a-8401-3ff3584e37b1',
    title: 'Swiftanalytics: Operations Data - Stock Cover (β)',
    url: '/ops-data/stock-cover',
  },
  {
    id: '4ac9fe11-df3e-4672-944b-1d40c7613389',
    title: 'Swiftanalytics: Operations Data - Inventory Snapshot (β)',
    url: '/ops-data/inventory-snapshot',
  },
  {
    id: '40afe433-88bd-43ee-8e38-c3ecea120037',
    title: 'Swiftanalytics: Operations Data - 3PL Performance (β)',
    url: '/ops-data/three-pl-performance',
  },
  {
    id: '0e4673e0-7c55-449f-b215-ce4776c13796',
    title: 'Swiftanalytics: TikTok - Sales Performance',
    url: '/tiktok/sales-performance',
  },
  {
    id: '64fa150f-9b7a-422d-9966-062de9fa4c90',
    title: 'Swiftanalytics: TikTok - Live Streaming',
    url: '/tiktok/live-streaming',
  },
  {
    id: 'f6c373c2-61f2-4ea5-9e13-65bd591a139e',
    title: 'Swiftanalytics: Warung Pintar - Home',
    url: '/',
  },
  {
    id: '3743e310-3111-498a-8de2-ae263734dd8c',
    title: 'Swiftanalytics: Warung Pintar - Sales - Daily & Run Rate',
    url: '/warpin/sales/daily-and-run-rate',
  },
  {
    id: 'ec94d9a7-de04-4c09-b4a7-06a8752e86c3',
    title: 'Swiftanalytics: Warung Pintar - Sales - SKU Contribution',
    url: '/warpin/sales/sku-contribution',
  },
  {
    id: 'bf181cec-8d2e-4b22-9e95-8894724095c7',
    title: 'Swiftanalytics: Warung Pintar - Sales - Heatmap Calendar',
    url: '/warpin/sales/heatmap-calendar',
  },
  {
    id: 'c4f5ec7a-534d-4c11-8545-ab3a12b57743',
    title: 'Swiftanalytics: Warung Pintar - Sales - By Area',
    url: '/warpin/sales/by-area',
  },
  {
    id: '8cf0a052-ec62-48f0-8f90-37a6d8b01479',
    title: 'Swiftanalytics: Warung Pintar - Sales - By Shipping',
    url: '/warpin/sales/by-shipping',
  },
  {
    id: '285fa35e-a0f1-4621-83a4-b21aebb0d792',
    title: 'Swiftanalytics: Warung Pintar - Sales - Raw Data',
    url: '/warpin/sales/raw-data',
  },
  {
    id: 'dba6f0a4-4826-4133-b939-64dda4a34964',
    title: 'Swiftanalytics: Warung Pintar - Customer Behaviour - Acquisition',
    url: '/warpin/cb/acquisition',
  },
  {
    id: 'e84550bc-4d55-4a7a-8131-6b0a1c141e1f',
    title:
      'Swiftanalytics: Warung Pintar - Customer Behaviour - Shopping Behavior',
    url: '/warpin/cb/shopping-behavior',
  },
  {
    id: 'fc3c328c-97fc-4ddf-8b4e-1a12518aab69',
    title: 'Swiftanalytics: Warung Pintar - Customer Behaviour - Retention',
    url: '/warpin/cb/retention',
  },
  {
    id: 'd7f1554c-0fd7-4f5f-bae4-5bc9d535f62f',
    title:
      'Swiftanalytics: Warung Pintar - Customer Behaviour - Basket Size & Assortment',
    url: '/warpin/cb/basket-size-assortment',
  },
  {
    id: '8c42c33d-94ee-4991-a452-1d79afb04252',
    title: 'Swiftanalytics: Testing Error Dashboard for SE',
    url: '/test-error',
  },
];

const isContainEmbeddingUrl = (pathname: string) => {
  const regex = /embedded/;
  return regex.test(pathname);
};

export const pushToDataLayer = async (
  obj: DataLayerType,
  pathname?: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);

  const PATHNAME = pathname as string;

  if (isContainEmbeddingUrl(PATHNAME)) {
    const parts = PATHNAME.split('/');
    const ID = parts[parts.length - 1]; // dashboard ID

    const dashboardObj = dashboadCollections.find(obj => obj.id === ID);
    const isFilterMessage = !obj.err_message?.includes('all_datasource_access');

    if (dashboardObj && isFilterMessage) {
      const bodyMessage = {
        text: `:rotating_light: *Swift Analytics Error!* :rotating_light:\n>*Page:* ${dashboardObj.title}\n>*URL:* ${SWAN_URL}${dashboardObj.url}\n>*Error Message:*\n${obj.err_message}`,
        mrkdwn: true,
      };
      fetch(WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(bodyMessage),
      })
        .then(response => {
          // Handle success if needed
          console.log('Success push to Slack Channel!', response);
        })
        .catch(err => {
          // Handle error if needed
          console.log('Error push to Slack Channel!', err);
        });
    }
  }
};
